.certificate-header h1 {
    margin-bottom: 40px;
    font-size: 2.5rem;
    font-family: var(--primaryFont);
}

.certificate-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1rem;
}